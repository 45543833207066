import { HeadMetadata } from "@components/headMetadata";
import { Layout } from "@components/layout";
import { graphql } from "gatsby";
import React from "react";
import InfoArticle from "./info-article-template";
import { ArticleInfoEnum } from "src/enums/articleInfoEnum";

export default function BlogInformation({ data }: any) {
  const { information } = data;

  return (
    <Layout>
      <HeadMetadata location={information?.name ?? ""}></HeadMetadata>
      <InfoArticle
        briefDescription={information?.briefDescription ?? ""}
        id={information?.id ?? ""}
        name={information?.name ?? ""}
        link={`/blog${information?.sublink ?? ""}`}
        coverImage={
          information?.coverImage?.localFile?.childImageSharp
            ?.gatsbyImageData ?? ""
        }
        rawMarkdownBody={
          information?.pageContent?.data?.childMarkdownRemark
            ?.rawMarkdownBody ?? ""
        }
        medias={information?.pageContent?.medias ?? ""}
        mainLink={`/blog`}
        mainLinkName={`Blog`}
        type={ArticleInfoEnum.Blog}
        miscData={{
          author: information?.author ?? "",
          publishedAt: information?.publishedAt ?? "",
        }}
      ></InfoArticle>
    </Layout>
  );
}

export const query = graphql`
  query blogTemplate($url: String) {
    information: strapiBlogInformation(sublink: { eq: $url }) {
      briefDescription
      name
      author
      publishedAt
      strapi_id
      sublink
      coverImage {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
      }
      pageContent {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
    }
  }
`;
